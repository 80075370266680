var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative input-empty-text",class:{ 'input-height-auto': _vm.heightAuto }},[_c('div',{staticClass:"position-absolute text-center",style:({
      top: '12px',
      left: '12px',
      'z-index': 3
    })},[_c('v-icon',{attrs:{"color":_vm.iconColor}},[_vm._v(" "+_vm._s(_vm.prependIcon)+" ")]),_c('div',{staticClass:"text-caption o-20"},[_vm._v(" "+_vm._s(_vm.prependIconLabel)+" ")])],1),_c('CodeMirror',{ref:"cmRef",staticClass:"pt-2 cm-style yaml-input",class:( _obj = {
      'pl-9': _vm.prependIcon,
      'pl-12': _vm.prependIconLabel,
      'blue-border': _vm.prependIcon && _vm.focussed && !_vm.error,
      'red-border': _vm.prependIcon && _vm.error,
      'plain-border': _vm.prependIcon && !_vm.focussed && !_vm.error,
      'original-border': !_vm.prependIcon
    }, _obj[_vm.backgroundColor] = true, _obj ),attrs:{"data-cy":"code-mirror-input","value":_vm.internalValue,"options":_vm.editorOptions},on:{"input":function($event){return _vm._handleInput($event)},"focus":function($event){_vm.focussed = true},"blur":function($event){_vm.focussed = false}}}),_vm._t("default"),_c('div',{staticClass:"text-caption red--text min-height pl-4"},[_vm._v(_vm._s(_vm.error))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }