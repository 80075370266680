var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.labelsAlign)?_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"super-imposed-icon-set cursor-pointer",attrs:{"text":""}},on),[_c('v-icon',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"color":"red"}},[_vm._v("label")]),_c('v-icon',{staticClass:"nudge-icon-left",staticStyle:{"pointer-events":"none"},attrs:{"x-small":"","color":"white"}},[_vm._v(" not_interested ")])],1)]}}],null,false,3540074014)},[_c('v-card',{staticClass:"pa-0",attrs:{"tile":"","max-width":"320"}},[_c('v-card-title',{staticClass:"subtitle pb-1"},[_vm._v(_vm._s(_vm.agentOrLabel)+" ")]),_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"font-weight-bold pb-4"},[_vm._v(" "+_vm._s(_vm.infoMessage))]),_c('div',[_vm._v("You can see your agent labels in the "),_c('router-link',{attrs:{"target":"_blank","to":{
            name: 'dashboard',
            params: { tenant: _vm.tenant.slug },
            query: { agents: '' }
          }}},[_c('span',[_vm._v("agents tab")])]),_vm._v(".")],1),_c('div',[_vm._v(" You can see and edit your "+_vm._s(_vm.flowOrFlowRun)+" labels in the "),(_vm.location !== 'flowPageDetails')?_c('router-link',{attrs:{"to":{
            name: 'flow-run',
            params: { id: _vm.flowRun.id, tenant: _vm.tenant.slug }
          }}},[_vm._v("flow run details tile")]):_c('span',[_vm._v(_vm._s(_vm.flowOrFlowRun)+" details tile")]),_vm._v(".")],1),(_vm.flow && _vm.flow.is_schedule_active)?_c('div',{staticClass:"mt-4"},[_vm._v(" If you need to edit labels on many scheduled flow runs, you can pause the schedule and update the flow labels on the "),(_vm.location !== 'flowPageDetails')?_c('router-link',{attrs:{"to":{
            name: 'flow',
            params: { id: _vm.flow.id, tenant: _vm.tenant.slug }
          }}},[_vm._v("flow details tile")]):_vm._e(),_vm._v(" and then turn your schedule back on. ")],1):_vm._e(),_c('div',{staticClass:"mt-4"},[_vm._v(" For more information check out the docs on "),_c('a',{attrs:{"href":_vm.docsLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.docsName))]),_vm._v(" .")])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }