var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align-end":""}},[_c('v-text-field',{ref:"form",staticClass:"mx-4",attrs:{"type":_vm.newType || _vm.defaultType,"rules":[_vm.rules.typeCheck]},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-select',{staticClass:"select mx-4",attrs:{"items":[
              { value: 'Array', text: 'List' },
              { value: 'string', text: 'String' },
              { value: 'object', text: 'Dictionary' },
              { value: 'number', text: 'Integer' },
              { value: 'boolean', text: 'Boolean' }
            ]},on:{"change":function($event){return _vm.resetValidation()}},model:{value:(_vm.defaultType),callback:function ($$v) {_vm.defaultType=$$v},expression:"defaultType"}})],1)]}}])},[_c('span',[_vm._v("Select the type that this parameter should be set to. (Defaults to current parameter type).")])]),_c('v-btn',{staticClass:"mr-4 mt-4",attrs:{"depressed":""},on:{"click":_vm.reset}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":function($event){return _vm.setIndividualParam(_vm.parameter)}}},[_vm._v(" Update ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }